import {
  initQuotesWs,
  subscribeQuotesEvent,
  subscribeQuotesChannel,
  unsubscribeQuotesChannel,
} from '@/services/socket-quotes';

export const state = () => ({
  markets: {},
  symbolRates: {},
});

export const mutations = {
  saveMarkets(state, markets) {
    state.markets = markets;
  },
  setMarketPrice(state, symbol) {
    if (state.markets[symbol.symbol]) {
      state.markets[symbol.symbol].price = symbol.rate;
    }
  },
  setRates(state, symbol) {
    state.symbolRates[symbol.symbol] = symbol;
  },
};

export const actions = {
  async getMarkets({ state, commit, dispatch }) {
    function onMarketUpdate(data) {
      data.forEach((symbol) => {
        commit('setMarketPrice', symbol);
      });
    }

    if (state.markets.length) return state.markets;
    else {
      const { data } = await dispatch('loadMarkets');

      const markets = Object.fromEntries(
        data.map((m) => [
          m.name,
          {
            ...m,
            nameUI: m.name.replace('/', ''),
            price: 0,
          },
        ])
      );
      commit('saveMarkets', markets);

      initQuotesWs();
      unsubscribeQuotesChannel('markets');
      subscribeQuotesChannel('markets');

      if (+process.env.VUE_APP_QUOTES_VERSION === 2) {
        subscribeQuotesEvent('updated', onMarketUpdate);
      } else {
        subscribeQuotesEvent('subscription', onMarketUpdate);
      }

      return markets;
    }
  },
  async loadMarkets(context, params) {
    return await this.$axios.get('/markets', { params });
  },
  async getRate({ state, dispatch, commit }, { from, to }) {
    if (from === to) return 1;

    const symbol = `${from}/${to}`;
    if (!state.symbolRates[symbol]) {
      const response = await dispatch('loadRate', { from, to });
      commit('setRates', response.data);
    }

    if (!state.symbolRates[symbol]) {
      return 1;
    }

    return state.symbolRates[symbol].rate;
  },
  async loadRate(context, { from, to }) {
    return await this.$qapi.get(`/markets?symbol=${from}/${to}`);
  },
};
