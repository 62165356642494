<template>
  <div :class="[$i18n.locale === 'ar' ? 'app-rtl' : '', `theme-${theme}`]">
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | ${siteName}` : siteName }}</template>
    </metainfo>
    <router-view></router-view>
    <notifications />
    <broadcasting />
  </div>
</template>

<script>
import Broadcasting from '@/components/broadcasting';

export default {
  name: 'App',
  components: {
    Broadcasting,
  },
  data() {
    return {
      siteName: this.$getConfigValue('appName', 'VUE_APP_NAME'),
      theme: this.$getConfigValue('appTheme', 'VUE_APP_THEME'),
    };
  },
  beforeMount() {
    const favicon = document.getElementById('favicon');
    favicon.href = this.$getConfigValue('faviconPath', 'VUE_APP_FAVICON_PATH');
  },
  mounted() {
    if (this.$store.getters['auth/loggedIn']) {
      this.$store.dispatch('accounts/getAccounts');
      this.$store.dispatch('markets/getMarkets');
      // this.$store.dispatch('chat/initChat');
    }
  },
};
</script>

<style scoped>
.app-rtl {
  direction: rtl;
  text-align: right;
}
</style>
