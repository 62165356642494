import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import VueAxios from 'vue-axios';
import BootstrapVue3 from 'bootstrap-vue-3';
import VueApexCharts from 'vue3-apexcharts';
import vClickOutside from 'click-outside-vue3';
import { registerScrollSpy } from 'vue3-scroll-spy';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { AuthImage } from './directives/AuthImage';
import * as Sentry from '@sentry/vue';

import Maska from 'maska';
import VueClipboard from 'vue3-clipboard';
import Notifications from '@kyvg/vue3-notification';
import { i18n } from './i18n.js';
import initAxios from '@/plugins/axios/index';
import axiosInstanceQapi from '@/plugins/axios/qapi';
import moment from 'moment';

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import store from './state/store';

import '../src/design/app.scss';

import './plugins/vee-validate';

import { broadcasting } from './services/broadcasting';

const app = createApp(App);

async function init() {
    const response = await fetch('/config.json');
    const appConfig = await response.json();

    function getConfigValue(key, envKey) {
        return appConfig[key] !== undefined ? appConfig[key] : process.env[envKey];
    }

    app.config.globalProperties.$appConfig = appConfig;

    app.config.globalProperties.$getConfigValue = (key, envKey) => {
        return getConfigValue(key, envKey);
    };

    const axiosInstance = initAxios(app);

    app
        .use(VueAxios, axiosInstance)
        .use(store)
        .use(router)
        .use(createMetaManager())
        .use(metaPlugin)
        .use(BootstrapVue3)
        .use(VueApexCharts)
        .use(vClickOutside)
        .use(i18n)
        .use(registerScrollSpy)
        .use(Maska)
        .use(VueClipboard, {
            autoSetContainer: true,
            appendToBody: true,
        })
        .use(Notifications);

    app.directive('auth-image', AuthImage);

    app.config.globalProperties.$broadcasting = broadcasting;
    moment.locale(localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE || 'en');
    app.config.globalProperties.$moment = moment;

    Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
    });

    store.$axios = axiosInstance;
    store.$qapi = axiosInstanceQapi;

    app.mount('#app');
}

init();