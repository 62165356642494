import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export const i18n = createI18n({
  locale: localStorage.getItem('lang') || process.env.VUE_APP_I18N_LOCALE || 'en',
  legacy: false,
  globalInjection: true,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});

const availableLanguages = [
  { language: 'en', title: 'English' },
  { language: 'ru', title: 'Русский' },
  { language: 'it', title: 'Italian' },
  { language: 'de', title: 'Deutsch' },
  { language: 'pl', title: 'Polska' },
  { language: 'pt', title: 'Portugal' },
  { language: 'es', title: 'Espanol' },
  { language: 'fr', title: 'Français' },
  { language: 'cs', title: 'Czech' },
  { language: 'ro', title: 'Română' },
  { language: 'kk', title: 'Қазақ' },
  { language: 'ar', title: 'عربي' },
  { language: 'ja', title: '日本語' },
];

function getEnabledLanguages() {
  return availableLanguages;

  // const envLanguages = process.env.VUE_APP_ENABLED_LANGUAGES;
  // if (envLanguages) {
  //   const enabledLanguages = envLanguages.split(',').map(lang => lang.trim());
  //
  //   return availableLanguages.filter(lang => enabledLanguages.includes(lang.language));
  // }
  //
  // // Exclude Japanese if env variable is not set
  // return availableLanguages.filter(lang => lang.language !== 'ja');
}

export const enabledLanguages = getEnabledLanguages();