<script>
import Pusher from 'pusher-js';
import { authComputed } from '@/state/helpers';

window.Pusher = Pusher;

export default {
  data() {
    return {
      echo: null,
    };
  },
  computed: {
    ...authComputed,
  },
  methods: {
    connect() {
      const token = this.$store.getters['auth/token'];

      if (!this.$broadcasting.echo) {
        this.$broadcasting.init(token);
      }

      this.$broadcasting.connect();
    },
    disconnect() {
      if (!this.$broadcasting.echo) return;
      this.$$broadcasting.disconnect();
    },
  },
  watch: {
    ['currentUser.id']: {
      immediate: true,
      handler(status) {
        status ? this.connect() : this.$broadcasting.disconnect();
      },
    },
  },
  render() {
    return '';
  },
};
</script>
