export const state = {
    countries: null,
}

export const mutations = {
    SET_COLLECTION(state, {key, data}) {
        state[key] = data;
    },
}

export const actions = {
    countries({ state, commit, dispatch }, params = {}) {
        return new Promise(function(resolve, reject) {
                if (state.countries?.length && !Object.keys(params).length) resolve(state.countries);
                else {
                    dispatch('loadCountries', params).then(({data}) => {
                        commit('SET_COLLECTION', {key: 'countries', data});
                        resolve(data);
                    }).catch(error => reject(error));
                }
            }
        );
    },
    loadCountries(context, params = {}) {
        return this.$axios.get("/countries", { params });
    },
};