import axios from 'axios';
import store from '@/state/store';
import { i18n } from '@/i18n';

function initAxios(context) {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      'Content-type': 'application/json',
      Brand: context.config.globalProperties.$getConfigValue('appKey', 'VUE_APP_KEY'),
    },
  });

  axiosInstance.interceptors.request.use((request) => {
    request.headers.Authorization = store.getters['auth/loggedIn'] ? `Bearer ${store.getters['auth/token']}` : '';
    request.headers['Accept-Language'] = i18n.global.locale.value;

    return request;
  });

  axiosInstance.interceptors.response.use(
      function (response) {
        if (response.request.responseType === 'arraybuffer') return response;

        return response.data;
      },
      function (error) {
        if (error.response.status === 401) {
          localStorage.removeItem('auth_token');
          if (!location.pathname.includes('/auth/')) {
            location.reload(true);
          }
        }

        return Promise.reject(error);
      }
  );

  return axiosInstance;
}

export default initAxios;
