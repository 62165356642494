export const state = {
  chat: {
    messages: [],
  },
  loading: {
    messages: false,
  },
};

export const mutations = {
  SET_CHAT(state, chat) {
    state.chat = chat;
  },
  SET_LOADING_MESSAGES(state, value) {
    state.loading.messages = value;
  },
  ADD_MESSAGE(state, value) {
    state.chat = { ...state.chat, messages: [...state.chat.messages, value] };
  },
};

export const actions = {
  initChat({ dispatch }) {
    dispatch('getChat');
  },
  listenEcho({ state }) {
    return this.$broadcasting.echo.private(`chat-rooms.${state.chat.id}.common`);
  },
  scrollToBottom() {
    const messagesEl = document?.querySelector('.chat-messages');
    if (messagesEl) {
      messagesEl.scroll({ top: messagesEl.scrollHeight, behavior: 'smooth' });
    }
  },
  listenNotifications({ state, commit, dispatch }, notification) {
    if (!notification) {
      return;
    }
    if (notification.key === 'chat.new_message') {
      commit('ADD_MESSAGE', { ...notification.params, is_own: false });

      this.$axios.post(`/chat/${notification.params.id}/read`).catch((error) => {
        console.warn(error);
      });

      this.$broadcasting.echo.private(`chat-rooms.${state.chat.id}.common`).whisper('reading', {
        chatId: state.chat.id,
      });

      dispatch('scrollToBottom');
    }
  },
  async getChat({ state, commit, dispatch, rootState }) {
    commit('SET_LOADING_MESSAGES', 'messages', true);

    this.$axios
      .get('/chat')
      .then(({ data }) => {
        if (data) {
          commit('SET_CHAT', data);
          this.$broadcasting.echo.private(`chat-rooms.${state.chat.id}.common`).whisper('reading', {
            chatId: state.chat.id,
          });
          this.$broadcasting.echo
            .private(`clients.${rootState.auth.currentUser.id}.branding`)
            .notification((value) => dispatch('listenNotifications', value));
          dispatch('listenEcho');

          this.commit('auth/SET_CURRENT_USER', {
            ...rootState.auth.currentUser,
            unread_messages_count: 0,
          });

          dispatch('scrollToBottom');
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        commit('SET_LOADING_MESSAGES', 'messages', false);
      });
  },
};
