import {defineRule} from "vee-validate";
import {i18n} from "@/i18n";
import {isEmpty} from "@/plugins/vee-validate/helpers";
import moment from "moment";

defineRule('date', (value, params) => {
    if (isEmpty(value)) {
        return true;
    }

    const format = params[0] || 'YYYY-MM-DD';

    return validate(value, format) || i18n.global.t('Validation.Date', { format });
});

function validate(value, format) {
    if (Array.isArray(value)) {
        return value.every(val => moment(val, format, true).isValid());
    }

    return moment(value, format, true).isValid();
}
