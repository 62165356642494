import {defineRule} from "vee-validate";
import {i18n} from "@/i18n";
import {isEmpty} from "@/plugins/vee-validate/helpers";

defineRule('phone', (value) => {
    if (isEmpty(value)) {
        return true;
    }

    return validate(value) || i18n.global.t('Validation.Phone');
});

function validate(value) {
    const re = /^\+[0-9]{9,15}$/;

    if (Array.isArray(value)) {
        return value.every(val => re.test(String(val)));
    }

    return re.test(String(value));
}
