import { defineRule } from 'vee-validate';
import { i18n } from '@/i18n';
import { isEmpty } from '@/plugins/vee-validate/helpers';
import { email } from '@vee-validate/rules';

defineRule('phone_email', (value) => {
  if (isEmpty(value)) {
    return true;
  }

  return validateNumber(value) || email(value) || i18n.global.t('Validation.PhoneEmail');
});

function validateNumber(value) {
  const re = /^\+[0-9]{9,15}$/;

  if (Array.isArray(value)) {
    return value.every((val) => re.test(String(val)));
  }

  return re.test(String(value));
}
