import { defineRule } from "vee-validate";
import { max_value } from "@vee-validate/rules";
import { i18n } from "@/i18n";

defineRule("max_value", (value, params, { field }) => {
  return (
    max_value(value, params) ||
    i18n.global.t("Validation.MaxValue", {
      attribute: field,
      max_value: params[0],
    })
  );
});
