import Echo from 'laravel-echo';

export const broadcasting = {
  echo: null,
  init(token) {
    this.echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_KEY,
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      wsHost: process.env.VUE_APP_ECHO_URL,
      wsPort: process.env.VUE_APP_PUSHER_PORT,
      forceTLS: false,
      enabledTransports: ['ws', 'wss'],
      authEndpoint: `${process.env.VUE_APP_ENVIRONMENT !== 'local' ? '//' : ''}${
        process.env.VUE_APP_ECHO_AUTH_ENDPOINT
      }`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
  },
  connect() {
    this.echo.connector.pusher.connect();
  },
  disconnect() {
    if (!this.echo) return;
    this.echo.disconnect();
  },
};
