import store from '@/state/store';

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/home/index'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/auth/login'),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        next({ name: 'home' });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('./views/auth/register'),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        next({ name: 'home' });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
  {
    path: '/auth/:token',
    name: 'auth',
    component: () => import('./views/auth/tokenAuth'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('./views/auth/forgot-password'),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        next({ name: 'home' });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('./views/auth/reset-password'),
    beforeEnter(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        next({ name: 'home' });
      } else if (routeTo.query.token && routeTo.query.email) {
        // Continue to the login page
        next();
      } else {
        next({ name: 'forgot-password' });
      }
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('./views/auth/logout'),
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('auth/logOut');

      const authRequiredOnPreviousRoute = routeFrom.matched.some((route) => route.meta.authRequired);
      // Navigate back to previous page, or home as a fallback
      next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom });
    },
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { authRequired: true },
    component: () => import('./views/user/profile'),
    children: [
      {
        path: 'personal',
        name: 'personal',
        alias: '/profile',
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/components/pages/profile/settings-tab'),
      },
      {
        path: 'verification',
        name: 'verification',
        component: () => import('@/components/pages/profile/verification-tab'),
      },
    ],
  },
  {
    path: '/open-account',
    name: 'open-account',
    meta: { authRequired: true },
    component: () => import('./views/trading/open-account'),
  },
  {
    path: '/accounts',
    name: 'accounts',
    meta: { authRequired: true },
    component: () => import('./views/trading/accounts'),
  },
  {
    path: '/accounts/:id',
    name: 'accounts-show',
    meta: { authRequired: true },
    component: () => import('./views/trading/accounts-show'),
  },
  {
    path: '/deposit',
    name: 'deposit',
    meta: { authRequired: true },
    component: () => import('./views/transfers/deposit'),
  },
  {
    path: '/deposit/cryptocurrency',
    name: 'deposit-cryptocurrency',
    meta: { authRequired: true },
    component: () => import('./views/transfers/deposit-cryptocurrency'),
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    meta: { authRequired: true },
    component: () => import('./views/transfers/withdraw'),
  },
  {
    path: '/swap',
    name: 'swap',
    meta: { authRequired: true },
    component: () => import('./views/transfers/swap'),
  },
  {
    path: '/transfers-report',
    name: 'transfers-report',
    meta: { authRequired: true },
    component: () => import('./views/transfers/transfers-report'),
  },
  {
    path: '/support',
    name: 'support',
    meta: { authRequired: true },
    component: () => import('./views/chat/index'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('./views/utility/404'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '404',
  },
];
