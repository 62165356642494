console.log('VUE_APP_QUOTES_VERSION', +process.env.VUE_APP_QUOTES_VERSION);
// old sockets
let client;
let connectionEstabliched;
let subscribedChannels = [];
const subscribers = {};

function emitSubscribers(event, data) {
  if (event in subscribers) {
    subscribers[event].forEach((callback) => {
      callback(data);
    });
  }
}

function subscribeNonActivatedChannels() {
  subscribedChannels.forEach((ch) => {
    if (!ch.activated) {
      client.send(JSON.stringify({ event: 'subscribe', channel: ch.channel }));
      ch.activated = true;
    }
  });
}

function resubsribeToChanngels() {
  subscribedChannels.forEach((ch) => {
    client.send(JSON.stringify({ event: 'subscribe', channel: ch.channel }));
  });
}

const initQuotesWsOld = () => {
  console.log('initQuotesWsOld');
  if (client) {
    console.warn('quotesWs already inited');
    return;
  }
  client = new WebSocket(process.env.VUE_APP_QUOTES_WS);
  client.addEventListener('message', (event) => {
    try {
      const message = JSON.parse(event.data);
      switch (message.event) {
        case 'ping':
          client.send(JSON.stringify({ event: 'pong' }));
          break;
        case 'connection':
          connectionEstabliched = true;
          subscribeNonActivatedChannels();
          break;
        default:
          emitSubscribers(message.event, message.data);
      }
    } catch (err) {
      console.warn(err);
    }
  });
  client.addEventListener('open', (event) => {
    console.log('Connected to socket-quotes', event);
    resubsribeToChanngels();
  });
  client.addEventListener('close', (event) => {
    console.warn('Quotes socket is closed. Reconnect will be attempted in 1 second.', event.reason);
    setTimeout(function () {
      initQuotesWsOld();
    }, 1000);
  });
  client.addEventListener('error', (event) => {
    console.warn('Quotes socket closed:', event.message);
    client.close();
  });
  return client;
};

const getQuotesWsOld = () => {
  console.log('getQuotesWsOld');
  return client;
};

const subscribeQuotesChannelOld = (channel) => {
  console.log('subscribeQuotesChannelOld');
  if (connectionEstabliched && !subscribedChannels.find((ch) => ch.channel === channel)) {
    client.send(JSON.stringify({ event: 'subscribe', channel }));
  }
  subscribedChannels.push({ activated: !!connectionEstabliched, channel });
};

const unsubscribeQuotesChannelOld = (channel) => {
  console.log('unsubscribeQuotesChannelOld');
  if (subscribedChannels.find((ch) => ch.channel === channel)) {
    client.send(JSON.stringify({ event: 'unsubscribe', channel }));
    subscribedChannels = subscribedChannels.filter((ch) => ch.channel !== channel);
  }
};

const subscribeQuotesEventOld = (event, callback) => {
  console.log('subscribeQuotesEventOld');
  if (!(event in subscribers)) {
    subscribers[event] = [];
  }
  subscribers[event].push(callback);
};

const unsubscribeQuotesEventOld = (event, callback) => {
  console.log('unsubscribeQuotesEventOld');
  if (event in subscribers) {
    subscribers[event] = subscribers[event].filter((cb) => cb !== callback);
  }
};

// new sockets
import Echo from 'laravel-echo';

let echoInstance;
const channelName = 'markets';
let eventSubscribers = {};
let connectionEstablished = false;
let initQuotesWsNew = null;

if (+process.env.VUE_APP_QUOTES_VERSION === 2) {
  initQuotesWsNew = () => {
    console.log('initQuotesWsNew');
    echoInstance = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_QUOTES_WS_KEY || 'app-key',
      wsHost: process.env.VUE_APP_QUOTES_WS_HOST || 'qws.mfury.xyz',
      wsPort: process.env.VUE_APP_QUOTES_WS_PORT,
      wssPort: process.env.VUE_APP_QUOTES_WS_PORT,
      forceTLS: false,
      encrypted: true,
      cluster: 'mt1',
      enabledTransports: ['ws', 'wss'],
    });

    echoInstance.connector.pusher.connection.bind('connected', () => {
      console.log('Connected to Quotes WebSocket');
      subscribeQuotesChannelNew('markets');
    });

    echoInstance.connector.pusher.connection.bind('disconnected', () => {
      console.warn('Disconnected from Quotes WebSocket. Reconnecting...');
      setTimeout(initQuotesWsNew, 15000);
    });

    return echoInstance;
  };
}

export const closeQuotesWs = () => {
  unsubscribeQuotesChannelNew('markets');
  echoInstance.disconnect();
};

const getQuotesWsNew = () => {
  console.log('getQuotesWsNew');
  return echoInstance;
};

const subscribeQuotesChannelNew = (channel = 'markets') => {
  if (!echoInstance) {
    initQuotesWsNew();
  }

  if (!connectionEstablished) {
    echoInstance.channel(channel).listen('updated', () => {});
    connectionEstablished = true;
  }
};

const unsubscribeQuotesChannelNew = (channel = 'markets') => {
  console.log('unsubscribeQuotesChannelNew');
  if (echoInstance) {
    echoInstance.leave(channel);
  }
};

const subscribeQuotesEventNew = (event, callback) => {
  console.log('subscribeQuotesEventNew');
  const eventKey = `${channelName}:${event}`;

  if (!eventSubscribers[eventKey]) {
    eventSubscribers[eventKey] = [];
  }

  eventSubscribers[eventKey].push(callback);

  echoInstance.channel(channelName).listen(`.${event}`, (data) => {
    if (eventSubscribers[eventKey]) {
      eventSubscribers[eventKey].forEach((cb) => cb(data));
    }
  });
};

const unsubscribeQuotesEventNew = (event, callback) => {
  console.log('unsubscribeQuotesEventOld');
  const eventKey = `${channelName}:${event}`;

  if (eventSubscribers[eventKey]) {
    eventSubscribers[eventKey] = eventSubscribers[eventKey].filter((cb) => cb !== callback);

    if (eventSubscribers[eventKey].length === 0) {
      delete eventSubscribers[eventKey];
    }
  }
};

export const initQuotesWs = () => (+process.env.VUE_APP_QUOTES_VERSION === 2 ? initQuotesWsNew() : initQuotesWsOld());
export const getQuotesWs = () => (+process.env.VUE_APP_QUOTES_VERSION === 2 ? getQuotesWsNew() : getQuotesWsOld());
export const subscribeQuotesChannel = (channel) =>
  +process.env.VUE_APP_QUOTES_VERSION === 2 ? subscribeQuotesChannelNew(channel) : subscribeQuotesChannelOld(channel);
export const unsubscribeQuotesChannel = (channel) =>
  +process.env.VUE_APP_QUOTES_VERSION === 2
    ? unsubscribeQuotesChannelNew(channel)
    : unsubscribeQuotesChannelOld(channel);
export const subscribeQuotesEvent = (event, callback) =>
  +process.env.VUE_APP_QUOTES_VERSION === 2
    ? subscribeQuotesEventNew(event, callback)
    : subscribeQuotesEventOld(event, callback);
export const unsubscribeQuotesEvent = (event, callback) =>
  +process.env.VUE_APP_QUOTES_VERSION === 2
    ? unsubscribeQuotesEventNew(event, callback)
    : unsubscribeQuotesEventOld(event, callback);
