import axios from 'axios';

export const AuthImage = {
  mounted: (el, binding) => {
    setImgSrc(el, binding);
  },
  updated: (el, binding) => {
    setImgSrc(el, binding);
  },
};

async function setImgSrc(el, binding) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    const imageUrl = binding.value;
    el.style.visibility = 'hidden';

    if (typeof caches !== 'undefined') {
      const cache = await caches.open('image-cache');
      const cachedResponse = await cache.match(imageUrl);

      if (cachedResponse) {
        const blob = await cachedResponse.blob();
        const reader = new FileReader();
        reader.onloadend = function () {
          el.src = reader.result;
          el.style.visibility = 'visible';
        };
        reader.readAsDataURL(blob);
        return;
      }
    }

    axios
      .get(imageUrl, {
        responseType: 'blob',
        headers: {
          Brand: binding.instance.$getConfigValue('appKey', 'VUE_APP_KEY'),
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      })
      .then(async function (resp) {
        const mimeType = resp.headers['content-type'].toLowerCase();
        const blob = new Blob([resp.data], { type: mimeType });
        if (typeof caches !== 'undefined') {
          const cache = await caches.open('image-cache');
          cache.put(imageUrl, new Response(blob, { headers: { 'Content-Type': mimeType } }));
        }

        const reader = new FileReader();
        reader.onloadend = function () {
          el.src = reader.result;
          el.style.visibility = 'visible';
        };
        reader.readAsDataURL(blob);
      })
      .catch(function () {
        el.src = imageUrl;
        el.style.visibility = 'visible';
      });
  }
}
