export const state = {
  authToken: localStorage.getItem('auth_token'),
  currentUser: {},
  loading: {
    balance: false,
    balanceRate: false,
  },
  balance: null,
  balanceRate: null,
  visibleBalance: true,
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
  },
  SET_AUTH_TOKEN(state, newValue) {
    state.authToken = newValue;

    if (newValue === null) localStorage.removeItem('auth_token');
    else localStorage.setItem('auth_token', newValue);
  },
  SET_LOADING_BALANCE(state, payload) {
    state.loading.balance = payload;
  },
  SET_ACCOUNT_BALANCE(state, payload) {
    state.balance = payload;
  },
  SET_BALANCE_RATE(state, payload) {
    state.balanceRate = payload;
  },
  SET_VISIBLE_BALANCE(state, payload) {
    state.visibleBalance = payload;
  },
};

export const getters = {
  // Whether the user is currently logged in.
  userBalanceExchanged(state, getters, rootState, rootGetters) {
    if (state.balance === null || state.balanceRate === null) {
      return null;
    }

    const realAccounts = rootGetters['accounts/realAccounts'];

    if (realAccounts.length && realAccounts.every((acc) => acc.currency.key === state.currentUser.base_currency.key)) {
      return realAccounts.reduce((current, acc) => current + acc.amount, 0).toFixed(2);
    }

    return (state.balance.balance_full * state.balanceRate).toFixed(2);
  },
  loggedIn(state) {
    return !!state.authToken;
  },
  token(state) {
    return state.authToken;
  },
  user(state) {
    return state.currentUser;
  },
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ dispatch }) {
    dispatch('validate');
  },
  toggleVisibleBalnce({ state, commit }) {
    commit('SET_VISIBLE_BALANCE', !state.visibleBalance);
  },
  fetchBalance({ commit }) {
    console.log('fetch balance');
    commit('SET_LOADING_BALANCE', true);
    this.$axios
      .get('/accounts/balance')
      .then(({ data }) => {
        commit('SET_ACCOUNT_BALANCE', data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        commit('SET_LOADING_BALANCE', false);
      });
  },
  async getBalanceRate({ commit, state }) {
    const rate = await this.dispatch('markets/getRate', {
      from: 'USD', // amount comes in usd
      to: state.currentUser.base_currency.key,
    });

    commit('SET_BALANCE_RATE', rate);
  },
  // Logs in the current user.
  logIn({ commit, dispatch, getters }, { phone, password, remember, email } = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return this.$axios
      .post('/auth/login', {
        phone,
        email,
        password,
        remember,
      })
      .then(({ data }) => {
        commit('SET_AUTH_TOKEN', data.access_token);
        dispatch('fetchBalance');

        return dispatch('validate');
      });
  },

  // Logs out the current user.
  logOut({ state, commit }) {
    return new Promise((resolve, reject) => {
      const token = state.authToken;

      commit('SET_CURRENT_USER', {});
      commit('SET_AUTH_TOKEN', null);

      return this.$axios
        .post(
          '/auth/logout',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Register the user
  register({ commit, dispatch, getters }, payload = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return this.$axios.post('/auth/registration', payload).then(({ data }) => {
      commit('SET_AUTH_TOKEN', data.access_token);

      return dispatch('validate');
    });
  },

  // Reset password email
  sendResetEmail({ dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return this.$axios
      .post('/auth/password/reset', {
        email,
      })
      .then(({ data }) => {
        return data;
      });
  },

  // Reset password
  resetPassword({ commit, dispatch, getters }, payload = {}) {
    if (getters.loggedIn) return dispatch('validate');

    return this.$axios.put('/auth/password/reset', payload).then(({ data }) => {
      commit('SET_AUTH_TOKEN', data.access_token);

      return dispatch('validate');
    });
  },

  // Validates the current user's token
  // with new data from the API.
  validate({ commit, state, dispatch }, noRedirect = false) {
    if (!state.authToken) return Promise.resolve(null);
    if (state.currentUser.id) return state.currentUser;

    return this.$axios
      .get('/auth/me', {
        headers: {
          Authorization: `Bearer ${state.authToken}`,
        },
      })
      .then(({ data }) => {
        commit('SET_CURRENT_USER', data);
        dispatch('fetchBalance');
        dispatch('getBalanceRate');

        return data;
      })
      .catch((error) => {
        commit('SET_AUTH_TOKEN', null);
        if (!noRedirect) {
          dispatch('logOut');
        }

        throw error;
      });
  },

  setToken({ commit }, token) {
    commit('SET_AUTH_TOKEN', token);
  },
};
