export const state = {
  loading: {
    accounts: false,
  },
  accounts: [],
};

export const getters = {
  accountsMap(state) {
    return Object.fromEntries(state.accounts.map((acc) => [acc.id, acc]));
  },
  realAccounts(state) {
    return state.accounts.filter((acc) => !acc.is_demo);
  },
};

export const mutations = {
  SET_LOADING_ACCOUNTS(state, loading) {
    state.loading.accounts = loading;
  },
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },
};

export const actions = {
  async getAccounts({ state, commit, dispatch }, refresh = false) {
    if (state.accounts.length && !refresh) {
      return state.accounts;
    } else {
      commit('SET_LOADING_ACCOUNTS', true);
      const { data } = await dispatch('loadAccounts');
      commit('SET_ACCOUNTS', data);
      commit('SET_LOADING_ACCOUNTS', false);
    }
  },
  async loadAccounts() {
    return await this.$axios.get('/accounts');
  },
};
